import React, { useEffect } from 'react'

import { ResponsiveLine } from '@nivo/line'
import Row  from 'react-bootstrap/Row';
import Col  from 'react-bootstrap/Col';

import styles from './researchgraph.module.scss'

import { useBaseQuery } from '../../hooks/use-base-query'
import graphsInfo from '../../data/json/graphsInfo'

const ResearchGraph = ( { criteria, criteriacode, vscriteria, vs_criteriacode, graphcode } ) => {

    const querydata = useBaseQuery()
    const graphInfo = graphsInfo[graphcode]

    const colors = [
        "#11CFE7",
        "#473B91",
        "#FFD71D",
        "#F3510F"
    ]

    const getGraphDetails = (criteria, criteria_code, graph_values, graph_data) => {
        const raw = querydata[criteria_code].edges
        graph_values.forEach( i => {
            let gdata
            if (i.value_code === 'hcc0t+hch0t') {
                gdata = {
                    id: (i.hasOwnProperty('label_key') ? criteria + " ("+ i.label_key +")" : criteria ),
                    data: raw.map( j => (
                        { 
                            x: j.node.age, 
                            y: Number(Number(j.node['hcc0t']) + Number(j.node['hch0t'])).toFixed(2)
                        }
                    )),
                    color: colors[graph_data.length]
                }
            } else if (i.value_code === 'my02t+hyc2t+hyh2t') {
                gdata = {
                    id: (i.hasOwnProperty('label_key') ? criteria + " ("+ i.label_key +")" : criteria ),
                    data: raw.map( j => (
                        { 
                            x: j.node.age, 
                            y: Number(Number(j.node['my02t']) + Number(j.node['hyc2t']) + Number(j.node['hyh2t'])).toFixed(2)
                        }
                    )),
                    color: colors[graph_data.length]
                }
            } else if (i.value_code === 'my01t+hyc1t+hyh1t') {
                gdata = {
                    id: (i.hasOwnProperty('label_key') ? criteria + " ("+ i.label_key +")" : criteria ),
                    data: raw.map( j => (
                        { 
                            x: j.node.age, 
                            y: Number(Number(j.node['my01t']) + Number(j.node['hyc1t']) + Number(j.node['hyh1t'])).toFixed(2)
                        }
                    )),
                    color: colors[graph_data.length]
                }
            } else if (i.value_code === 'hyc2t+hyh2t') {
                gdata = {
                    id: (i.hasOwnProperty('label_key') ? criteria + " ("+ i.label_key +")" : criteria ),
                    data: raw.map( j => (
                        { 
                            x: j.node.age, 
                            y: Number(Number(j.node['hyc2t']) + Number(j.node['hyh2t'])).toFixed(2)
                        }
                    )),
                    color: colors[graph_data.length]
                }
            } else if (i.value_code === 'hyc1t+hyh1t') {
                gdata = {
                    id: (i.hasOwnProperty('label_key') ? criteria + " ("+ i.label_key +")" : criteria ),
                    data: raw.map( j => (
                        { 
                            x: j.node.age, 
                            y: Number(Number(j.node['hyc1t']) + Number(j.node['hyh1t'])).toFixed(2)
                        }
                    )),
                    color: colors[graph_data.length]
                }
            } else {
                gdata = {
                    id: (i.hasOwnProperty('label_key') ? criteria + " ("+ i.label_key +")" : criteria ),
                    data: raw.map( j => (
                        { 
                            x: j.node.age, 
                            y: Number( j.node[i.value_code] ).toFixed(2) 
                        }
                    )),
                    color: colors[graph_data.length]
                }
            }
            graph_data.push(gdata)
        });
    }
    
    const createReportData = () => {
        let graphData = []
        getGraphDetails(criteria, criteriacode, graphInfo.values, graphData)
        getGraphDetails(vscriteria, vs_criteriacode, graphInfo.values, graphData)
        return graphData
    }

    let data = createReportData()

    useEffect(() => {
        data = createReportData()
    });

    const myTooltip = ({point}) => {
        return (
            <div style={{
				background: "black",
				padding: "9px 12px",
				border: "1px solid #ccc",
				borderRadius: "25px",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "column",
				color: "white",
			}}>
                <div><span className="box" style={{background: point.serieColor}}></span> <strong>{point.serieId}</strong></div>
                Age: {point.data.x}
                <br />
                {graphInfo.legend_y_key}: {point.data.y}
            </div>
        )
    }

    const note = () => {
        return (
            <Row className="border border-dark text-justify mt-3">
                <span className="notes p-3">
                    <b>Notes:</b> <a href="http://www.countingwomenswork.org">Counting Women’s Work</a> project estimates. The current country database includes estimates from seventeen countries: Bangladesh, Colombia, Costa Rica, Germany, Ghana, India, Italy, Mauritius, Mexico, Mongolia, Senegal, South Africa, Spain, Thailand, United States, Uruguay, and Vietnam.  The world and other average measures are equal-weighted averages of countries in that region or group.  Consumption age profiles are calculated based on how much unpaid care work time is produced in each household and the household structure.  See the <a href="http://www.countingwomenswork.org">Counting Women’s Work</a> website for details and access to estimates.      
                </span>
            </Row>
        ) 
    }

    const reportTitle = () => {
        if(Array.isArray(graphInfo.title_key)){
            return graphInfo.title_key
                        .map( line => <>{line}<br /></>)
                        .reduce((prev, curr) => [prev, curr])
        }else{
            return graphInfo.title_key
        }
    }

    const reportLabels = () => {
        if(graphInfo.values.length === 1){
            return (
                <Row>
                    <Col className="col-12 mb-md-2 col-md-4 offset-md-2 text-center">
                        <span className="box color-cri-f"></span> <strong className="mr-3">{criteria}</strong> 
                    </Col>
                    <Col className="col-12 mb-md-2 col-md-4 text-center">
                        <span className="box color-cri-m ml-3"></span> <strong className="mr-3">{vscriteria}</strong>
                    </Col>
                </Row>
            )
        } else {
            return (
                <Row>
                    <Col className="col-12 mb-md-2 col-md-4 offset-md-2 text-center">
                        <strong className="mr-3">{criteria}:</strong> <span className="box color-cri-f"></span> Female <span className="box color-cri-m ml-3"></span> Male
                    </Col>
                    <Col className="col-12 mb-md-2 col-md-4 text-center">
                        <strong className="mr-3">{vscriteria}:</strong> <span className="box color-vs-f"></span> Female <span className="box color-vs-m ml-3"></span> Male
                    </Col>
                </Row>
            )
        }
    }

    return (
        <div>
            <p className="text-center mb-4"><strong>{reportTitle()}</strong></p>
            {reportLabels()}
            <Row className={styles.graph}>
                <ResponsiveLine
                    data={data}
                    margin={{ top: 20, right: 20, bottom: 50, left: 60 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', stacked: false, min: 'auto', max: 'auto' }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        tickValues: [10,20,30,40,50,60,70,80,90],
                        legend: 'Age',
                        legendOffset: 36,
                        legendPosition: 'middle'
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: graphInfo.legend_y_key,
                        legendOffset: -40,
                        legendPosition: 'middle'
                    }}
                    colors={colors}
                    enablePoints={false}
                    lineWidth={2}
                    useMesh={true}
                    tooltip={myTooltip}
                /> 
            </Row>
            <Row>
                <span><b>Source: </b>{graphInfo.source_key}</span>
            </Row>
            {note()}
        </div>
    )
}

export default ResearchGraph