import { useStaticQuery, graphql } from "gatsby"

export const useBaseQuery = () => {
    const querydata = useStaticQuery(graphql`
        query ReportQuery {
            world_list: allAllNormalizedCsv(filter: {criteria: {eq: "The World"}}) {
                data: distinct(field: value)
            }
            development_list: allAllNormalizedCsv(filter: {criteria: {eq: "Development"}}) {
                data: distinct(field: value)
            }
            continent_list: allAllNormalizedCsv(filter: {criteria: {eq: "Continent"}}) {
                data: distinct(field: value)
            }
            countries_list: allAllNormalizedCsv(filter: {criteria: {eq: "Country"}}) {
                data: distinct(field: value)
            }
            countries: allAllNormalizedCsv {
                distinct(field: value)
            }
            the_world: allAllNormalizedCsv(filter: {value: {eq: "The World"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            mongolia: allAllNormalizedCsv(filter: {value: {eq: "Mongolia"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            thailand: allAllNormalizedCsv(filter: {value: {eq: "Thailand"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            bangladesh: allAllNormalizedCsv(filter: {value: {eq: "Bangladesh"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            mauritius: allAllNormalizedCsv(filter: {value: {eq: "Mauritius"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            colombia: allAllNormalizedCsv(filter: {value: {eq: "Colombia"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            costa_rica: allAllNormalizedCsv(filter: {value: {eq: "Costa Rica"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            germany: allAllNormalizedCsv(filter: {value: {eq: "Germany"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            ghana: allAllNormalizedCsv(filter: {value: {eq: "Ghana"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            india: allAllNormalizedCsv(filter: {value: {eq: "India"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            italy: allAllNormalizedCsv(filter: {value: {eq: "Italy"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            mexico: allAllNormalizedCsv(filter: {value: {eq: "Mexico"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            senegal: allAllNormalizedCsv(filter: {value: {eq: "Senegal"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            south_africa: allAllNormalizedCsv(filter: {value: {eq: "South Africa"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            spain: allAllNormalizedCsv(filter: {value: {eq: "Spain"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            united_states: allAllNormalizedCsv(filter: {value: {eq: "United States"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            uruguay: allAllNormalizedCsv(filter: {value: {eq: "Uruguay"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            vietnam: allAllNormalizedCsv(filter: {value: {eq: "Vietnam"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            africa: allAllNormalizedCsv(filter: {value: {eq: "Africa"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            asia: allAllNormalizedCsv(filter: {value: {eq: "Asia"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            europe: allAllNormalizedCsv(filter: {value: {eq: "Europe"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            latin_america: allAllNormalizedCsv(filter: {value: {eq: "Latin America"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            north_america: allAllNormalizedCsv(filter: {value: {eq: "North America"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            advanced_economies: allAllNormalizedCsv(filter: {value: {eq: "Advanced Economies"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
            emerging_economies: allAllNormalizedCsv(filter: {value: {eq: "Emerging Economies"}}) {
                edges {
                node {
                    ...retfields
                }
                }
            }
        }

        fragment retfields on AllNormalizedCsv {
            criteria
            value
            age
            hcc0m
            hcc0t
            hch0m
            hch0t
            hyc1m
            hyc2m
            hyc1t
            hyc2t
            hyh1m
            hyh1t
            hyh2t
            hyh2m
            mc00m
            my01m
            my02m
            my01t
            my02t
            oe01t
            oe02t
        }
    `)

  return querydata
}