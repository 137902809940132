import React from 'react'
import Navbar  from 'react-bootstrap/Navbar';
import Nav  from 'react-bootstrap/Nav';

import Logo from '../../data/images/logo.inline.svg'
import { Link } from 'gatsby'
import { injectIntl } from "gatsby-plugin-intl"

import styles from './header.module.scss'

const Header = ({ intl }) => {
    return (
        <header className={styles.header}>
            
            <Navbar bg="white" expand="lg">
                <div className="container z1">
                <Navbar.Brand className="col-2">
                    <Link className={styles.title} to="/">
                    <Logo /> 
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="col-12">
                        <ul className={styles.navList}>
                            <li className="col-lg-3">
                                <Link className={styles.navItem} activeClassName={styles.activeNavItem} to="/">
                                    {intl.formatMessage({ id: "menu.section_1" })}
                                </Link>
                            </li>
                            <li className="col-lg-3">
                                <Link className={styles.navItem} activeClassName={styles.activeNavItem} to="/age_profiles">
                                    {intl.formatMessage({ id: "menu.section_2" })}
                                </Link>
                            </li>
                            <li className="col-lg-3">
                                <a href="https://www.countingwomenswork.org/publications/working-papers" target="_blank" rel="noopener noreferrer">
                                    {intl.formatMessage({ id: "menu.section_3" })}
                                </a>
                            </li>
                            <li className="col-lg-3">
                                <Link to="/#bookcover">
                                    {intl.formatMessage({ id: "menu.section_4" })}
                                </Link>
                            </li>
                        </ul>
                    </Nav>
                </Navbar.Collapse>
                </div>
            </Navbar>  
            
        </header>
    )
}

export default injectIntl(Header)