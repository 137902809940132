const graphsInfo = {
  time_production_sum_in_hours_per_week: {
    title_key: "Production – Market and Unpaid Care Work (hours per week)",
    source_key: "Counting Women's Work",
    legend_y_key: "Hours per Week",
    values: [
      {
        label_key: "Female",
        value_code: "my02t+hyc2t+hyh2t"
      },
      {
        label_key: "Male",
        value_code: "my01t+hyc1t+hyh1t"
      }
    ]
  },
  time_production_market_in_hours_per_week: {
    title_key: "Production – Market Work (hours per week)",
    source_key: "Counting Women's Work",
    legend_y_key: "Hours per Week",
    values: [
      {
        label_key: "Female",
        value_code: "my02t"
      },
      {
        label_key: "Male",
        value_code: "my01t"
      }
    ]
  },
  time_production_in_hours_per_week: {
    title_key: "Production – Unpaid Care Work (hours per week)",
    source_key: "Counting Women's Work",
    legend_y_key: "Hours per Week",
    values: [
      {
        label_key: "Female",
        value_code: "hyc2t+hyh2t"
      },
      {
        label_key: "Male",
        value_code: "hyc1t+hyh1t"
      }
    ]
  },
  time_production_care_in_hours_per_week: {
    title_key: "Production – Direct Care (hours per week)",
    source_key: "Counting Women's Work",
    legend_y_key: "Hours per Week",
    values: [
      {
        label_key: "Female",
        value_code: "hyc2t"
      },
      {
        label_key: "Male",
        value_code: "hyc1t"
      }
    ]
  },
  time_production_hwk_in_hours_per_week: {
    title_key: "Production – Housework (hours per week)",
    source_key: "Counting Women's Work",
    legend_y_key: "Hours per Week",
    values: [
      {
        label_key: "Female",
        value_code: "hyh2t"
      },
      {
        label_key: "Male",
        value_code: "hyh1t"
      }
    ]
  },
  time_consumption_in_hours_per_week: {
    title_key: ["Consumption – Unpaid Care Work",
                "(both sexes - hours per week)"],
    source_key: "Counting Women's Work",
    legend_y_key: "Hours per Week",
    values: [
      {
        value_code: "hcc0t+hch0t"
      }
    ]
  },
  time_consumption_care_in_hours_per_week: {
    title_key: ["Consumption – Direct Care",
                "(both sexes - hours per week)"],
    source_key: "Counting Women's Work",
    legend_y_key: "Hours per Week",
    values: [
      {
        value_code: "hcc0t"
      }
    ]
  },
  time_consumption_hwk_in_hours_per_week: {
    title_key: ["Consumption – Housework ",
                "(both sexes - hours per week)"],
    source_key: "Counting Women's Work",
    legend_y_key: "Hours per Week",
    values: [
      {
        value_code: "hch0t"
      }
    ]
  },
  time_consumption_edu_in_hours_per_week: {
    title_key: "Education (hours per week)",
    source_key: "Counting Women's Works",
    legend_y_key: "Hours per Week",
    values: [
      {
        label_key: "Female",
        value_code: "oe02t"
      },
      {
        label_key: "Male",
        value_code: "oe01t"
      }
    ]
  },
}

export default graphsInfo;