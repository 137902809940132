import React from 'react'

import Form from 'react-bootstrap/Form'
import { useBaseQuery } from '../../hooks/use-base-query'


const CriteriaSelector = ({className, value, onChange}) => {

    const { world_list, development_list, continent_list, countries_list } = useBaseQuery()

    var worldList = world_list.data.map(function(name){
        return <option key={name} value={name}>{name}</option>
    })

    var developmentList = development_list.data.map(function(name){
        return <option key={name} value={name}>{name}</option>
    })

    var continentList = continent_list.data.map(function(name){
        return <option key={name} value={name}>{name}</option>
    })

    var countryList = countries_list.data.map(function(name){
        return <option key={name} value={name}>{name}</option>
    })

    return (
        <div className={className}>
            <Form.Control as="select" value={value} onChange={onChange}>
                { worldList }
                <optgroup label="By Economies Development">
                    { developmentList }
                </optgroup>
                <optgroup label="By Continents">
                    { continentList }
                </optgroup>
                <optgroup label="By Countries">
                    { countryList }
                </optgroup>
            </Form.Control>
        </div>  
    )
}

export default CriteriaSelector