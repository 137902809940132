import React from 'react'
import Img from "gatsby-image"
import Row  from 'react-bootstrap/Row';
import { useStaticQuery, graphql } from 'gatsby'
import { injectIntl } from "gatsby-plugin-intl"

import styles from './bookcover.module.scss'


const BookCover = ({ intl }) => {

    const data = useStaticQuery(graphql`
        query MyQuery {
            file(relativePath: { eq: "data/images/Default.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                fluid(maxWidth: 700, quality: 100) {
                ...GatsbyImageSharpFluid
                }
            }

            
            }
        }`)

    return (
        <div className={styles.BookCover + " dark-bck"} id="bookcover">
            <h2 className={styles.title + " col-md-6 offset-md-3"}>{intl.formatMessage({ id: "bookcover.title" })}</h2>
            
            <Img fluid={data.file.childImageSharp.fluid} className=" book" alt="Cover of Time and use transfers in the Americas"/>
            
            <Row className="cta1">
                <a className="button" href="https://www.amazon.com/Time-Use-Transfers-Americas-Generations-ebook/dp/B07Q7NV97Y/" target="_blank" rel="noopener noreferrer">
                    {intl.formatMessage({ id: "bookcover.call_to_action_1" })}
                </a>
            </Row>
            <Row className="cta2">
                <a className="button" href="https://www.springer.com/gp/book/9783030118051" target="_blank" rel="noopener noreferrer">
                    {intl.formatMessage({ id: "bookcover.call_to_action_2" })}
                </a>
            </Row>
            
                
        </div>
        
    )
}

export default injectIntl(BookCover)