import React from 'react'

import Container from "react-bootstrap/Container";
import Header from '../../components/common/header'
import '../../styles/index.scss'
import layoutStyles from './layout.module.scss'

const Layout = (props) => {
    return (
        <div className={layoutStyles.container}>
            <Header>
                <Container className={layoutStyles.content}>

                </Container>
            </Header>

            <div className={layoutStyles.content}>
                
                {props.children}

            </div> 
        </div>
    )
}

export default Layout