import React, { useState } from 'react'

import Layout from '../components/common/layout'
import Head from '../components/common/head'
import ResearchGraph from '../components/insights/researchgraph'
import GraphSelector from '../components/insights/graphSelector'
import CriteriaSelector from '../components/utils/criteriaSelector'
import Row  from 'react-bootstrap/Row';
import Col  from 'react-bootstrap/Col';
import BookCover from '../components/home/bookcover'

const Insights = () => {
    
    const [criteria, setCriteria] = useState("The World")
    const handleCriteriaChange = (event) => {
        setCriteria(event.target.value)
    }
    let criteriacode = criteria.replace(/\s+/g, '_').toLowerCase()

    const [vscriteria, setVsCriteria] = useState("Colombia")
    const handleVsCriteriaChange = (event) => {
        setVsCriteria(event.target.value)
    }
    let vs_criteriacode = vscriteria.replace(/\s+/g, '_').toLowerCase()
    const [graphcode, setGraphCode] = useState("time_production_in_hours_per_week")
    const handleGraphCodeChange = (event) => {
        setGraphCode(event.target.value)
    }

    return (
        <Layout>
            <Head title="Insights"/>
            <div className="container mt-2 mt-md-5 light-bck text-center">
                <h2>Age profiles</h2>
                <hr className="separator" />
                <h4>Please select the locations and criteria below to compare by age</h4>
                <Row className="mb-md-3 mt-md-5">
                    <Col className="col-12 d-block d-sm-none text-left">
                        <h4>Locations:</h4>
                    </Col>
                    <Col className="col-12 col-md-5">
                        <CriteriaSelector value={criteria} onChange={handleCriteriaChange}/>
                    </Col>
                    <Col className="col-12 col-md-2 mt-2 text-center">
                        <h4>vs</h4>
                    </Col>
                    <Col className="col-12 col-md-5">
                        <CriteriaSelector value={vscriteria} onChange={handleVsCriteriaChange}/>
                    </Col>
                </Row>
                <Row className="mb-3 mb-md-5 mt-3 mt-md-0">
                    <Col className="col-12 d-block d-sm-none text-left">
                        <h4>Criteria:</h4>
                    </Col>
                    <Col className="col-12">
                        <GraphSelector value={graphcode} onChange={handleGraphCodeChange}/>
                    </Col>
                </Row>
                <Row className="graph mb-4">
                    <Col className="col-12"> 
                        <ResearchGraph criteria={criteria} criteriacode={criteriacode} vscriteria={vscriteria} vs_criteriacode={vs_criteriacode} graphcode={graphcode}/>
                    </Col>
                </Row>
            </div>
            <BookCover />
        </Layout>
    )
}

export default Insights