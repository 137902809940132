
import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const Head = ({ title }) => {
    const data = useStaticQuery(graphql`
        query{
            site{
                siteMetadata {
                    title
                    description
                    url
                    image
                }
            }
        }
    `)

    return (
        <Helmet>
            {/* General tags */}
            <title>{data.site.siteMetadata.title}</title>
            <meta name="description" content={data.site.siteMetadata.description} />
            {/* OpenGraph tags */}
            <meta name="og:url" content={data.site.siteMetadata.url} />
            <meta name="og:title" content={data.site.siteMetadata.title} />
            <meta name="og:description" content={data.site.siteMetadata.description} />
            <meta name="og:image" content={data.site.siteMetadata.image} />
            <meta name="og:type" content="website" />
            {/* Twitter Card tags */}
            <meta name="twitter:title" content={data.site.siteMetadata.title} />
            <meta name="twitter:description" content={data.site.siteMetadata.description} />
            <meta name="twitter:image" content={data.site.siteMetadata.image+"?123"} />
            <meta name="twitter:card" content="summary_large_image" />
            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.0/css/all.css" integrity="sha384-lZN37f5QGtY3VHgisS14W3ExzMWZxybE1SJSEsQp9S+oqd12jhcu+A56Ebc1zFSJ" crossorigin="anonymous"></link>
        </Helmet>
    )
}


export default Head