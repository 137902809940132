import React from 'react'

import Form from 'react-bootstrap/Form'

import graphsInfo from '../../data/json/graphsInfo'

const GraphSelector = ({className, value, onChange}) => {

    const graphListData = Object.getOwnPropertyNames(graphsInfo)

    const graphList = graphListData.map( name => {
        const optionTitle =  (Array.isArray(graphsInfo[name].title_key) ? graphsInfo[name].title_key.join(' ') : graphsInfo[name].title_key)
        return <option key={name} value={name}>{optionTitle}</option>
    })

    return (
        <div className={className}>
            <Form.Control as="select" value={value} onChange={onChange}>
                { graphList }
            </Form.Control>
        </div>  
    )
}

export default GraphSelector